import { Box, Button } from '@axazara/raiton-atoms';
import { useClipboard } from '@axazara/raiton-utils';
import { Creative } from 'utilities';
import YouTube from 'react-youtube';

export function CreativeVideoItem({ creative }: { creative: Creative }) {
  const { copied: creativesImageCopied, copy: copyCreativesImage } = useClipboard({ timeout: 500 });

  const opts = {
    height: '190',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Box className="space-y-4">
      <Box className="flex flex-col justify-between gap-4 border rounded-3xl p-4 border-neutral-90">
        <Box className="flex flex-col gap-3 md:mb-2">
          <YouTube iframeClassName="rounded-3xl w-full" videoId={creative.youtube_video_id} opts={opts} />
        </Box>
        <Button size="xs" onClick={() => copyCreativesImage(creative.video_url)} variant="outline">
          {creativesImageCopied ? 'copied' : 'copy video link'}
        </Button>
      </Box>
    </Box>
  );
}
